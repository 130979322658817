import { v4 as uuidv4 } from 'uuid';

import { doc, collection, setDoc, getDoc, getDocs } from 'firebase/firestore';

import { db, auth } from './firebase-init';

import { getUserDetails } from './UserUtils';

export async function submitNewPost(emoji) {
  // get user info
  const user = auth.currentUser;
  getUserDetails(user.email).then(userDetails => {
    const id = uuidv4();
    const post = {
      id: id,
      author: {
        name: userDetails.about.name,
        tag: userDetails.about.tag,
        avatar: userDetails.about.avatar,
        id: userDetails.id,
      },
      emoji: emoji,
      content: '',
      meta: {
        postDateTime: new Date().toISOString(),
        comments: 0,
        retweets: 0,
        likes: 0,
        topComment: {},
      },
    };
    setDoc(doc(db, 'posts', id), post)
      .then(() => {
        console.log('Document written with ID: ', id);
      })
      .catch(e => {
        console.error('Error adding document: ', e);
      });

    return post;
  });
}

export async function addLikeToPost(postId, liked) {
  const user = auth.currentUser;
  if (!user) {
    return;
  }
  const postRef = doc(db, 'posts', postId);
  getDoc(postRef).then(doc => {
    if (!doc.exists()) {
      console.log('No such document!');
      return;
    }
    const post = doc.data();
    if (liked) {
      post.meta.likes--;
      post.meta.liked = false;
    } else {
      post.meta.likes++;
      post.meta.liked = true;
    }
    console.log(post);
    setDoc(postRef, post)
      .then(() => {
        // add to user's liked posts
        getUserDetails(user.email).then(userDetails => {
          const likedPosts = userDetails.meta.likedPosts;
          if (liked) {
            // remove from liked posts
            if (likedPosts.indexOf(postId) === -1) {
              return;
            }
            likedPosts.splice(likedPosts.indexOf(postId), 1);
          } else {
            likedPosts.push(postId);
          }
          setDoc(doc(db, 'users', userDetails.id), userDetails)
            .then(() => {
              console.log('Document written with ID: ', userDetails.id);
            })
            .catch(e => {
              console.error('Error adding document: ', e);
            });
        });
        return post;
      })
      .catch(e => {
        console.error('Error adding document: ', e);
      });
  });
}

export async function getPosts() {
  const posts = [];
  const querySnapshot = await getDocs(collection(db, 'posts'));
  querySnapshot.forEach(doc => {
    posts.push(doc.data());
  });
  posts.sort((a, b) => {
    return new Date(b.meta.postDateTime) - new Date(a.meta.postDateTime);
  });
  return posts;
}

export const numberFormatter = number => {
  if (number >= 1000000000) {
    return `${(number / 1000000000).toFixed(1)}B`;
  } else if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  } else if (number >= 1000) {
    return `${(number / 1000).toFixed(1)}K`;
  } else {
    return `${number}`;
  }
};
