import React from 'react';
import './App.css';
import Feed from './components/Feed';
import NewPostHeader from './components/NewPostHeader';
import FeedWrapper from './components/FeedWrapper';
import SignIn, { CreateUserAccount } from './components/SignIn';

import { getUserDetails } from './utils/UserUtils';

import { auth } from './utils/firebase-init';

function App() {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);
  const [userDetails, setUserDetails] = React.useState(null);
  React.useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        // console.log('User is signed in.');
        getUserDetails(user.email).then(userDetails => {
          setUserDetails(userDetails);
          // console.log(userDetails);
        });
      } else {
        // console.log('No user is signed in.');
        setUser(null);
      }
    });
    setLoading(false);
  }, []);

  return (
    <div className="App">
      {loading && <div>Loading...</div>}
      {!loading && !user && <SignIn />}
      {!loading && user && !userDetails && <CreateUserAccount />}
      {!loading && user && userDetails && (
        <FeedWrapper>
          <NewPostHeader />
          <Feed />
        </FeedWrapper>
      )}
    </div>
  );
}

export default App;
