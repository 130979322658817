import React from 'react';
import Post from '../Post';
import './index.css';

import { getPosts } from '../../utils/PostUtils';

const Feed = () => {
  const [posts, setPosts] = React.useState([
    {
      author: {
        name: 'John Doe',
        tag: 'jdoe',
        avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      },
      emoji: '🔥',
      content: '',
      meta: {
        id: '1563ns88fke',
        postDateTime: '2021-01-01T12:00:00Z',
        comments: 0,
        retweets: 0,
        likes: 0,
        liked: false,
        topComment: {
          author: {
            name: 'Jane Doe',
            tag: 'janedoe',
            avatar: 'https://www.w3schools.com/howto/img_avatar.png',
          },
          content: 'This is a comment',
        },
      },
    },
    {
      author: {
        name: 'Elon Musk',
        tag: 'elonmusk',
        avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      },
      emoji: '🚀',
      content: '',
      meta: {
        id: '789yihu789o',
        postDateTime: '2021-01-01T12:00:00Z',
        comments: 0,
        retweets: 0,
        likes: 13000,
        liked: true,
        topComment: {
          author: {
            name: 'Jane Doe',
            tag: 'janedoe',
            avatar: 'https://www.w3schools.com/howto/img_avatar.png',
          },
          content: 'This is a comment',
        },
      },
    },
    {
      author: {
        name: 'Jeff Bezos',
        tag: 'jeffbezos',
        avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      },
      emoji: '📦',
      content: '',
      meta: {
        id: '87ty978hoiwbhj',
        postDateTime: '2021-01-01T12:00:00Z',
        comments: 4,
        retweets: 0,
        likes: 10,
        liked: false,
        topComment: {
          author: {
            name: 'Jane Doe',
            tag: 'janedoe',
            avatar: 'https://www.w3schools.com/howto/img_avatar.png',
          },
          content: 'This is a comment',
        },
      },
    },
    {
      author: {
        name: 'Nancy Pelosi',
        tag: 'nancypelosi',
        avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      },
      emoji: '🇺🇸',
      content: '',
      meta: {
        id: 'wfcy6532jhb',
        postDateTime: '2021-01-01T12:00:00Z',
        comments: 1,
        retweets: 1,
        likes: 0,
        liked: false,
        topComment: {
          author: {
            name: 'Jane Doe',
            tag: 'janedoe',
            avatar: 'https://www.w3schools.com/howto/img_avatar.png',
          },
          content: 'This is a comment',
        },
      },
    },
    {
      author: {
        name: 'Donald Trump',
        tag: 'donaldtrump',
        avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      },
      emoji: '🇺🇸',
      content: '',
      meta: {
        id: 'wfcwwey6532jhb',
        postDateTime: '2021-01-01T12:00:00Z',
        comments: 0,
        retweets: 0,
        likes: 1,
        liked: false,
        topComment: {
          author: {
            name: 'Jane Doe',
            tag: 'janedoe',
            avatar: 'https://www.w3schools.com/howto/img_avatar.png',
          },
          content: 'This is a comment',
        },
      },
    },
    {
      author: {
        name: 'Joe Biden',
        tag: 'joebiden',
        avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      },
      emoji: '🇺🇸',
      content: '',
      meta: {
        id: 'wfcy65e32jhb',
        postDateTime: '2021-01-01T12:00:00Z',
        comments: 0,
        retweets: 0,
        likes: 0,
        liked: false,
        topComment: {
          author: {
            name: 'Jane Doe',
            tag: 'janedoe',
            avatar: 'https://www.w3schools.com/howto/img_avatar.png',
          },
          content: 'This is a comment',
        },
      },
    },
    {
      author: {
        name: 'Chuck Norris',
        tag: 'chucknorris',
        avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      },
      emoji: '👊',
      content: '',
      meta: {
        id: 'wfcy653t42jhb',
        postDateTime: '2021-01-01T12:00:00Z',
        comments: 0,
        retweets: 0,
        likes: 0,
        liked: false,
        topComment: {
          author: {
            name: 'Jane Doe',
            tag: 'janedoe',
            avatar: 'https://www.w3schools.com/howto/img_avatar.png',
          },
          content: 'This is a comment',
        },
      },
    },
    {
      author: {
        name: 'John Doe',
        tag: 'jdoe',
        avatar: 'https://www.w3schools.com/howto/img_avatar.png',
      },
      emoji: '🔥',
      content: '',
      meta: {
        id: '1563nsr388fke',
        postDateTime: '2021-01-01T12:00:00Z',
        comments: 0,
        retweets: 0,
        likes: 0,
        liked: false,
        topComment: {
          author: {
            name: 'Jane Doe',
            tag: 'janedoe',
            avatar: 'https://www.w3schools.com/howto/img_avatar.png',
          },
          content: 'This is a comment',
        },
      },
    },
  ]);

  React.useEffect(() => {
    getPosts().then(posts => {
      setPosts(posts);
    });
  }, []);

  return (
    <div className="feed">
      {posts.map(post => (
        <Post post={post} key={post.meta.id} />
      ))}
    </div>
  );
};

export default Feed;
