import React from 'react';
import { FaGoogle, FaTwitter } from 'react-icons/fa';
import { auth } from '../../utils/firebase-init';

import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import { createNewUser } from '../../utils/UserUtils';

import './index.css';

export const CreateUserAccount = () => {
  const user = auth.currentUser;

  return (
    <div className="create-user-account">
      <div className="create-user-account-header">
        <h1>Almost there!</h1>
      </div>
      <p className="create-user-account-subheader">
        {`Hey ${user.displayName}, we just need a few more details to get you started!`}
        {` Your tag will be used to identify you on ballsitch.net. You can change it later if you want. This is what your profile will look like:`}
      </p>
      <div className="create-user-account-subheader-example">
        <div className="create-user-account-subheader-example-avatar">
          <img src={user.photoURL} alt="avatar" />
        </div>
        <span className="create-user-account-subheader-example-name-full">
          {user.displayName}
        </span>
        <br />
        <span className="create-user-account-subheader-example-name-tag">{``}</span>
      </div>
      <form
        className="create-user-account-form"
        onSubmit={e => {
          e.preventDefault();
          const tag = e.target.tag.value.toLowerCase();
          createNewUser(tag, user.displayName, user.photoURL)
            .then(userDetails => {
              window.location.reload();
            })
            .catch(e => {
              console.log(e);
            });
        }}
      >
        <div className="create-user-account-form-tag">
          <label htmlFor="tag">Choose your Tag!</label>
          <input
            type="text"
            id="tag"
            name="tag"
            placeholder="myreallycooltag"
            required
            maxLength={15}
            // dont allow anything but letters, numbers, and underscores
            // maxlength="15"
            pattern="^[a-zA-Z0-9_]*$"
            title="Letters, numbers, and underscores only"
            onChange={e => {
              document.querySelector(
                '.create-user-account-subheader-example-name-tag',
              ).innerHTML = `@${e.target.value}`;
            }}
          />
        </div>
        <button
          className="create-user-account-form-submit"
          type="submit"
          label="Create Account"
          aria-label="Create Account"
          title="Create Account"
        >
          Create Account
        </button>
      </form>
    </div>
  );
};

const SignIn = () => {
  const provider = new GoogleAuthProvider();

  const GoogleLogin = async () => {
    try {
      signInWithPopup(auth, provider).then(userCredential => {
        const user = userCredential.user;
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="sign-in">
      <div className="sign-in-header">
        <h1>Welcome to ballsitch.net!</h1>
      </div>
      <p className="sign-in-subheader">
        Right now, you can only sign in with Google. More sign in options coming
        soon!
      </p>
      <div className="sign-in-body">
        <div className="sign-in-body-button">
          <button
            className="sign-in-body-button-google"
            onClick={GoogleLogin}
            label="Sign in with Google"
            aria-label="Sign in with Google"
            title="Sign in with Google"
          >
            <FaGoogle /> Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
