import React, { useState, useEffect } from 'react';
import { submitNewPost } from '../../utils/PostUtils';
import { FaPaperclip } from 'react-icons/fa';
import EmojiPicker from 'emoji-picker-react';
import './index.css';

import { auth } from '../../utils/firebase-init';

const NewPostHeader = () => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emoji, setEmoji] = useState('');
  const [text, setText] = useState('');

  const handleKeyDown = event => {
    // console.log('User pressed: ', event.key);
    if (event.key === 'Backspace') {
      setEmoji('');
      setShowEmojiPicker(true);
    }
  };

  const handleOutsideClick = event => {
    if (event.target instanceof HTMLElement) {
      if (
        event.target.className.includes('new-post-header') ||
        event.target.className.includes('epr-')
      ) {
        return;
      }
      setShowEmojiPicker(false);
    }
  };

  const user = auth.currentUser;

  useEffect(() => {
    // listen for outside click
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="new-post-header">
      <div className="new-post-header-avatar">
        <img src={user.photoURL} alt="avatar" />
        <div className="new-post-header-avatar-name">
          <span className="new-post-header-avatar-name-full">
            {user.displayName}
          </span>
        </div>
      </div>
      <form
        className="new-post-header-form"
        onSubmit={e => {
          e.preventDefault();
          submitNewPost(emoji).then(post => {
            setText('');
            setEmoji('');
            setShowEmojiPicker(false);
          });
        }}
      >
        <div className="new-post-header-input">
          <input
            className="new-post-header-input-text"
            type="text"
            placeholder="Balls Itchy?"
            value={text + emoji}
            required
            onChange={e => {}}
            onFocus={e => {
              if (!text) {
                setText('Balls Itchy ');
              }
              setShowEmojiPicker(true);
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
        <button
          className="new-post-header-attachment-button"
          onClick={() => {
            window.alert('No');
          }}
          type="button"
          label="Attachment"
          aria-label="Attachment"
          title="Attachment"
        >
          <FaPaperclip className="new-post-header-attachment" />
        </button>
        <button
          className="new-post-header-input-button"
          type="submit"
          label="Post"
          aria-label="Post"
          title="Post"
        >
          Post
        </button>
      </form>
      {showEmojiPicker && !emoji && (
        <div className="new-post-header-emoji-picker">
          <EmojiPicker
            style={{
              position: 'absolute',
              bottom: '100px',
              right: '0px',
              zIndex: '1',
            }}
            onEmojiClick={(emoji, e) => {
              setEmoji(emoji.emoji);
              setShowEmojiPicker(false);
            }}
            emojiStyle="native"
          />
        </div>
      )}
    </div>
  );
};

export default NewPostHeader;
