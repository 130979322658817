import React, { useState } from 'react';
import {
  FaRegComment,
  FaRegHeart,
  FaHeart,
  FaRegShareSquare,
} from 'react-icons/fa';
import { FaRetweet } from 'react-icons/fa6';
import { numberFormatter } from '../../utils/PostUtils';
import './index.css';

import { addLikeToPost } from '../../utils/PostUtils';

const Post = ({ post }) => {
  const [liked, setLiked] = useState(post.meta.liked);
  return (
    <div className="post">
      <div className="post-header">
        <div className="post-avatar">
          <img src={post.author.avatar} alt="avatar" />
          <div className="post-avatar-name">
            <span className="post-avatar-name-full">{post.author.name}</span>
            <span className="post-avatar-name-tag">{`@${post.author.tag}`}</span>
          </div>
        </div>
      </div>
      <div className="post-content">
        {post.content && (
          <div className="post-content-text">
            <span>{post.content}</span>
          </div>
        )}
        {post.emoji && !post.content && (
          <div className="post-content-text">
            Balls Itchy <span>{post.emoji}</span>
          </div>
        )}
      </div>
      <div className="post-footer">
        <div className="post-footer-button">
          <button
            className="post-footer-button-comment"
            disabled
            onClick={() => {
              console.log('comment');
            }}
            label="Comment"
            aria-label="Comment"
            title="Comment"
          >
            <FaRegComment />
          </button>
          <button
            className="post-footer-button-retweet"
            disabled
            onClick={() => {
              console.log('retweet');
            }}
            label="Retweet"
            aria-label="Retweet"
            title="Retweet"
          >
            <FaRetweet />
          </button>
          <button
            className="post-footer-button-like"
            onClick={() => {
              setLiked(!liked);
              addLikeToPost(post.id, liked);
            }}
            label="Like"
            aria-label="Like"
            title="Like"
          >
            {liked ? <FaHeart /> : <FaRegHeart />}
          </button>
          <button
            className="post-footer-button-share"
            onClick={() => {
              console.log('share');
              navigator.clipboard.writeText(
                `https://ballsitch.net/${post.author.tag}/status/${post.id}`,
              );
            }}
            label="Share"
            aria-label="Share"
            title="Share"
          >
            <FaRegShareSquare />
          </button>
          <div className="post-footer-button-count">
            <span>
              {post.meta.comments !== 1
                ? `${numberFormatter(post.meta.comments)} replies`
                : `${numberFormatter(post.meta.comments)} reply`}
            </span>
            {' · '}
            <span>
              {post.meta.likes !== 1
                ? `${numberFormatter(post.meta.likes)} likes`
                : `${numberFormatter(post.meta.likes)} like`}
            </span>
          </div>
        </div>
      </div>
      <div className="post-divider" />
    </div>
  );
};

export default Post;
