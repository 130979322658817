import { v4 as uuidv4 } from 'uuid';

import { collection, addDoc, getDocs } from 'firebase/firestore';

import { db, auth } from './firebase-init';

export async function createNewUser(tag = '', name = '', avatar = '') {
  const user = auth.currentUser;
  const id = uuidv4();
  const userDetails = {
    id: id,
    about: {
      name: name || user.displayName,
      tag: tag || user.email.split('@')[0],
      avatar: avatar || user.photoURL,
      email: user.email,
    },
    meta: {
      active: true,
      private: false,
      joinedOn: new Date().toISOString(),
    },
  };
  try {
    const docRef = await addDoc(collection(db, 'users'), userDetails);
    console.log('Document written with ID: ', docRef.id);
    return userDetails;
  } catch (e) {
    console.error('Error adding document: ', e);
  }
  return userDetails;
}

export async function getUserDetails(email) {
  const querySnapshot = await getDocs(collection(db, 'users'));
  let userDetails = null;
  if (!querySnapshot) {
    return userDetails;
  }
  querySnapshot.forEach(doc => {
    if (doc.data().about.email === email) {
      userDetails = doc.data();
    }
  });
  return userDetails;
}
